import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// import IconButton from '@material-ui/core/IconButton';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import InstagramIcon from '@material-ui/icons/Instagram';
import clsx from 'clsx';

import styles from './Footer.styles';

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const isLoginPage = useRouteMatch({ path: '/login', exact: true });
  const isRegisterPage = useRouteMatch({ path: '/register', exact: true });

  const menuLinks = [
    { path: '/#support', title: 'Support' },
    // { path: '/about', title: 'About' },
    { path: '/terms', title: 'Terms of Service' },
    { path: '/privacy', title: 'Privacy Policy' },
  ];

  // const socialLinks = [
  //   {
  //     url: 'https://www.linkedin.com/company/premosocial/',
  //     icon: <LinkedInIcon fontSize="inherit" />,
  //   },
  //   {
  //     url: 'https://twitter.com/premosocial',
  //     icon: <TwitterIcon fontSize="inherit" />,
  //   },
  //   {
  //     url: 'https://www.instagram.com/premosocial/',
  //     icon: <InstagramIcon fontSize="inherit" />,
  //   },
  // ];

  return (
    <div
      className={clsx(classes.root, (isLoginPage || isRegisterPage) && 'white')}
    >
      <Container maxWidth="lg">
        <AppBar position="relative" color="transparent" elevation={0}>
          <Toolbar>
            <Grid container alignItems="flex-start">
              <Grid item xs={12} sm={3}>
                <Typography className={classes.copyright}>
                  &copy; {new Date().getFullYear()} Stratum Six LLC. All rights
                  reserved.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                className={classes.menuContainer}
                justifyContent="flex-end"
              >
                {menuLinks.map((menuLink, k) => (
                  <Button
                    key={`link-${k}`}
                    color="inherit"
                    component={Link}
                    to={menuLink.path}
                    size="small"
                    disableRipple
                  >
                    {menuLink.title}
                  </Button>
                ))}
              </Grid>
              {/* <Grid item xs={12} sm={3} className={classes.socialContainer}>
                {socialLinks.map((socialLink, k) => (
                  <IconButton
                    key={`social-${k}`}
                    size="small"
                    target="_blank"
                    href={socialLink.url}
                  >
                    {socialLink.icon}
                  </IconButton>
                ))}
              </Grid> */}
            </Grid>
          </Toolbar>
        </AppBar>
      </Container>
    </div>
  );
};

export default Footer;
