export default (theme) => ({
  root: {
    marginTop: 'auto',
    height: 70,
    '&.white': {
      marginTop: '0',
      backgroundColor: '#FFFFFF',
    },
  },
  copyright: {
    opacity: 0.5,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  menuContainer: {
    flexGrow: 1,
    textAlign: 'right',
    '& a': {
      textTransform: 'capitalize',
      opacity: 0.5,
      fontSize: 14,
      '&:hover': {
        opacity: 1,
        background: 'none',
      },
    },
  },
  socialContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    '& a': {
      opacity: 0.5,
      fontSize: 14,
      '&:hover': {
        opacity: 1,
        background: 'none',
      },
      '& svg': {
        fontSize: 18,
      },
    },
  },
});
