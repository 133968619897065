export default (theme) => ({
  hamburger: {
    color: '#ffffff',
  },
  menuContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  menuItemLeft: {
    display: 'flex',
    alignItems: 'center',
    '&.isHomePage': {
      color: theme.palette.common.white,
    },
  },
  dotIcon: {
    fontSize: 6,
    margin: '0 20px 0 30px',
    opacity: 0.5,
  },
  signUpButton: {
    borderRadius: 0,
    marginLeft: 20,
    color: '#021E3A',
  },
  rightButton: {
    color: '#021E3A',
  },
});
