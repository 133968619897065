export default {
  root: {
    borderRadius: '8px',
    padding: '11px 28px 9px',
    textTransform: 'capitalize',
  },
  sizeLarge: {
    padding: '15px 32px 13px',
  },
  outlinedSizeSmall: {
    padding: '11px 24px 9px',
    fontSize: 14,
  },
  outlinedSecondary: {
    borderWidth: '2px',
    '&:hover': {
      borderWidth: '2px',
    },
  },
};
