export default (theme) => ({
  root: {},
  headerOffset: theme.mixins.toolbar,
  navbar: {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #EAEBEE',
  },
  darkNavbar: {
    backgroundColor: theme.palette.common.hero,
  },
  transparentNavbar: {
    paddingTop: theme.spacing(2),
    // color: theme.palette.common.white,
    position: 'absolute',
    top: 0,
  },
  logo: {
    '& img': {
      float: 'left',
    },
  },
});
