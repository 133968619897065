import React from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { map } from 'lodash';
import MenuIcon from '@material-ui/icons/Menu';
import styles from './LoggedOutMenu.styles';
import clsx from 'clsx';
import {
  Button,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(styles);

const LoggedOutMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isHomePage = useRouteMatch({ path: '/', exact: true });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { to: '/#discover', name: 'Discover' },
    { to: '/#support', name: 'FAQ' },
    { to: '/login', name: 'Sing In' },
    { to: '/register', name: 'Sign Up' },
  ];

  const menuItemsDesktop = [
    {
      section: 'left',
      className: clsx(classes.menuItemLeft, isHomePage && 'isHomePage'),
      items: [
        { to: '/#discover', name: 'Discover' },
        { to: '/#support', name: 'FAQ' },
      ],
    },
    {
      section: 'right',
      className: classes.menuItemRight,
      items: [
        { to: '/login', name: 'Sign In', className: classes.rightButton },
        {
          to: '/register',
          name: 'Sign Up',
          variant: 'outlined',
          className: classes.signUpButton,
        },
      ],
    },
  ];

  const location = useLocation();

  React.useEffect(() => {
    // Scroll to the element with the ID from the fragment identifier
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  return (
    <Box className={classes.menuContainer}>
      <Hidden xsDown>
        {map(menuItemsDesktop, ({ section, className, items }) => (
          <Box className={className}>
            {section === 'left' && (
              <FiberManualRecordIcon
                fontSize="small"
                className={classes.dotIcon}
              />
            )}
            {map(items, ({ to, name, variant, className }) => (
              <Button
                to={to}
                component={Link}
                color="inherit"
                key={name}
                variant={variant}
                className={className}
              >
                {name}
              </Button>
            ))}
          </Box>
        ))}
      </Hidden>
      <Hidden smUp>
        <IconButton onClick={handleClick}>
          <MenuIcon className={isHomePage && classes.hamburger} />
        </IconButton>

        <Menu
          getContentAnchorEl={null}
          id="header-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {map(menuItems, ({ to, name }) => (
            <MenuItem component={Link} to={to} key={name} onClick={handleClose}>
              {name}
            </MenuItem>
          ))}
        </Menu>
      </Hidden>
    </Box>
  );
};

export default LoggedOutMenu;
