import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import premoLogo from 'static/branding/logo.svg';
import premoLogoContrast from 'static/branding/logo-contrast.svg';
import styles from './Header.styles';
import { useAuthState } from 'utils/Auth';
import LoggedOutMenu from './components/LoggedOutMenu';
import LoggedInMenu from './components/LoggedInMenu';

const useStyles = makeStyles(styles);

const Header = () => {
  const classes = useStyles();

  const isHomePage = useRouteMatch({ path: '/', exact: true });

  const authState = useAuthState();
  const isLoggedIn = !!authState.token;

  return (
    <div className={classes.root}>
      <AppBar
        color={isHomePage ? 'transparent' : 'default'}
        elevation={0}
        position="static"
        classes={{
          colorPrimary: classes.darkNavbar,
          colorTransparent: classes.transparentNavbar,
          colorDefault: classes.navbar,
        }}
      >
        <Toolbar>
          <Box className={classes.logo}>
            <Link to="/">
              <img
                src={isHomePage ? premoLogoContrast : premoLogo}
                alt="Premosocial.com"
                width="140px"
              />
            </Link>
          </Box>

          {isLoggedIn ? (
            <LoggedInMenu />
          ) : (
            <LoggedOutMenu isHomePage={isHomePage} />
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
